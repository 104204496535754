.crimsonServiceDeskHeader{
  text-align: center;
  height: 75px;
  width:100%;
  background-color:  #A60F2D;
  color:white;
  position: fixed;
}

.nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

.nav li {
  float: left;
}

.nav li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
.nav li a:hover {
  background-color: #111;
}