.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.search-container{
  padding-top: 150px;
}

.search-input{
  margin-top: 100px;
  margin-bottom: 25px;
  margin-right: 25px;
  width: 60%;
  height: 25px;
  border: 1px solid black;
}

.search-table{
  width: 25%;
  border: 1px solid black;
  margin: auto;
  margin-top: 25px;
}

.search-table tr{
  border: 1px solid black;
}

.search-bar{
  margin-bottom: 30px;
}

#search-button{
  background-color: #A60F2D;
  margin-top: 10px;
  margin-left: 20px;
}

.search-result-container{
  margin-top: 30px;
}

.expandChildTable:before {
  content: "+";
  display: block;
  cursor: pointer;
}
.expandChildTable.selected:before {
  content: "-";
}

.search-table-rows-container{
  width:320px;
  height:80px;
  overflow:auto;
}

.search-table-header-row{
  color:white;
  background-color:grey
}

#left-button{
  background-color: #A60F2D;
  float: left;
}

#right-button{
  background-color: #A60F2D;
  float: right;
}

#middle-button{
  background-color: #A60F2D;
  float: middle;
}

.container-div{
  padding: 30px;
}

#progress{
  color: #A60F2D
}

#progress-div{
  display: flex;
  justify-content: center;
  align-items: center;
}

#module-input{
  width: 300px;
}

#left-div{
  float: left;
}

.csd-link-container{
  padding-top: 30px;
}

.csd-link-container button{
  color: #A60F2D;
}

.checkbox{
  color: #A60F2D;
}